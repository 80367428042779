import {BasePage} from "./base"

import {swiperMainBanner} from "@/js/controllers/swiper-main-banner.js";
import {swiperCarousel} from "@/js/controllers/swiper-carousel.js";
import {swiperCarouselSpecial} from "@/js/controllers/swiper-carousel-special.js";
import {swiperCarouselOurWorks} from "@/js/controllers/swiper-carousel-our-works.js";

import '@pages/index.scss';

import {InlineForm} from "@/js/controllers/forms/inline-form.js";

/**
 * Главная
 * @type {void | *}
 */
const MainPage = BasePage.extend({
	defaults: {},
}, {
	init() {
		this._super()

		document.querySelectorAll('.slider-head').forEach((element) => swiperMainBanner(element));
		swiperCarousel(document.getElementById('items-slider'))
		swiperCarouselSpecial(document.getElementById('special-slider'))
		swiperCarouselOurWorks(document.getElementById('our-works'))

		document.querySelectorAll('form').forEach((element) => new InlineForm(element));


		// eslint-disable-next-line no-alert,no-undef

	},
})

// eslint-disable-next-line no-new
new MainPage(document.querySelector(`body`))
